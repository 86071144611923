<script setup>
  // pinia loading states:
  const localePath = useLocalePath();

  const items = ref([]);
  const router = useRouter();
  const route = useRoute();

</script>

<template>
  <div id="top" class="xs:mt-32 xs:pt-1 sm:mt-36 md:mt-36 lg:mt-44 xl:mt-40">
    <NavigationCenteredSearch />
    <TitleBar><NavigationBreadcrumb /></TitleBar>
    <div class="pt-4 xs:px-1 sm:px-1 md:px-4">
      <slot />
    </div>
    <Footer />
    <UiBackToTopButton />
    <WebshopModalCart />
    <WebshopCurrencyModal />
    <LazyUiSnackBar/>
  </div>
</template>